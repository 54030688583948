import React from 'react';
import { Link } from 'react-router-dom';

import './course-gallery.scss';
import AccordionGallery from '../../../components/accordion-gallery/accordion-gallery';
import TERM_IMAGES from './terms-image-data';

const CourseGallery = () => {
  return (
    <div className='course-container'>
      <div className='main-content'>
        <div className='course-details'>
          <h2 className='course-title'>Terms</h2>
          <p className='course-desc'>
            This section addresses Jiu Jitsu language—common terms that students
            will encounter during classes.
            <br />
            <br />
            If you are having issues viewing the images, try clearing your cache
            for this page.
          </p>
          <Link
            className='course-link-back'
            to={{
              pathname: '/',
            }}
          >
            <p className='course-link-back-text'>Return Home</p>
          </Link>
        </div>
        <div className='course-accordion-gallery'>
          <AccordionGallery imageData={TERM_IMAGES} />
        </div>
      </div>
    </div>
  );
};

export default CourseGallery;
