import React, { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap';
import { SRLWrapper } from 'simple-react-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ImageLightBox from '../image-lightbox/image-lightbox';
import './accordion-gallery.scss';

const AccordionGallery = ({ imageData }) => {
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnCLick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <button
        type='button'
        className={isCurrentEventKey ? 'title-toggle active' : 'title-toggle'}
        onClick={decoratedOnCLick}
      >
        {children}
      </button>
    );
  };

  let content = imageData.map((imageSet, index) => {
    return (
      <Accordion
        className='accordion-container'
        key={`${imageSet.id}${index}${imageSet.name}`}
      >
        <div className='accordion-row'>
          <ContextAwareToggle eventKey={`${index}`}>
            {index + 1}.{imageSet.name}
            <FontAwesomeIcon icon={faAngleRight} className='icon-toggle' />
          </ContextAwareToggle>
          <Accordion.Collapse
            eventKey={`${index}`}
            className='accordion-content'
          >
            <ImageLightBox imageLinks={imageSet.imageLinks} />
          </Accordion.Collapse>
        </div>
      </Accordion>
    );
  });

  return <SRLWrapper>{content}</SRLWrapper>;
};

export default AccordionGallery;
