import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as actions from '../../../store/actions/index';
import './delete-lesson-popup.scss';

const DeleteLessonPopup = ({
  show,
  handleClose,
  selectedLessonId,
  currentCourseSection,
  onDeleteCourseVideo,
  onLogout,
}) => {
  const storedToken = localStorage.getItem('token');
  if (!storedToken) {
    onLogout();
  }
  const onDeleteButtonPress = () => {
    onDeleteCourseVideo(storedToken, selectedLessonId, currentCourseSection);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className='delete-lesson-popup'>
        <Modal.Body className='modal-body'>
          <h3 className='delete-lesson-name'>
            Are you sure you want to delete this lesson?
          </h3>
          <div className='delete-lesson-notice'>
            Deleting this lesson will remove it permanately from the database.
            No one will be able to access this lesson afterwards.
          </div>
          <div className='delete-lesson-btn-container'>
            <button
              className='delete-btn delete-yes'
              onClick={onDeleteButtonPress}
            >
              Yes, Delete Lesson.
            </button>
            <button className='delete-btn delete-no' onClick={handleClose}>
              No, Cancel Operation.
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.course.error,
    loading: state.course.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteCourseVideo: (token, id, courseSection) =>
      dispatch(actions.deleteCourseVideo(token, id, courseSection)),
    onLogout: () => actions.logout(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLessonPopup);
