import axios from 'axios';

const {
  REACT_APP_NODE_ENV,
  REACT_APP_DEV_API_URL,
  REACT_APP_PROD_API_URL,
} = process.env;
let instance;
if (!REACT_APP_NODE_ENV || REACT_APP_NODE_ENV === 'dev') {
  // dev code
  instance = axios.create({
    baseURL: REACT_APP_DEV_API_URL,
  });
} else if (REACT_APP_NODE_ENV === 'prod') {
  // production code
  instance = axios.create({
    baseURL: REACT_APP_PROD_API_URL,
  });
}

export default instance;
