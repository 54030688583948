import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './course-admin-controls.scss';

import AddLessonPopup from '../../popups/add-lesson-popup/add-lesson-popup';

const CourseAdminControls = ({
  adminStatus,
  videoList,
  toggleDeleteMode,
  deleteMode,
  courseSection,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let content = null;
  let deleteModeText = 'Delete Lesson Mode: Off';
  if (deleteMode) deleteModeText = 'Delete Lesson Mode: On';
  if (adminStatus && videoList) {
    content = (
      <>
        <div className='admin-content-container'>
          <div className='admin-control-tip'>
            <p>
              "Add Lesson" will bring up a form that will allow you to create a
              new lesson to the list.
            </p>
            <p>
              "Delete Lesson Mode" will bring up the "delete" button for each
              item in the list.
            </p>
          </div>

          <div className='admin-button-container'>
            <button className='admin-button add-lesson' onClick={handleShow}>
              <FontAwesomeIcon icon={faPlus} className='icon' />
              Add Lesson To List
            </button>
            <button
              className='admin-button delete-lesson-mode'
              onClick={() => toggleDeleteMode()}
            >
              {deleteModeText}
            </button>
          </div>
        </div>
        <AddLessonPopup
          handleClose={handleClose}
          show={show}
          courseSection={courseSection}
        />
      </>
    );
  } else if (adminStatus && !videoList) {
    content = (
      <div className='admin-content-container'>
        <h3 className='admin-notice'>You are logged in as an admin.</h3>
      </div>
    );
  }
  return <>{content}</>;
};

export default CourseAdminControls;
