import React from 'react';
import { Modal } from 'react-bootstrap';
import './add-lesson-popup.scss';
import AddLessonForm from '../../forms/add-lesson-form';

const AddLessonPopup = ({ show, handleClose, courseSection }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} className='add-lesson-popup'>
        <Modal.Body className='modal-body'>
          <h3 className='add-lesson-name'>{courseSection}: New Lesson Form</h3>
          <div className='form-container'>
            <AddLessonForm
              courseSection={courseSection}
              handleClose={handleClose}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddLessonPopup;
