import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CourseLessonList from '../../../components/course-lesson-list/course-lesson-list';
import CourseAdminControls from '../../../components/admin/course-admin-controls/course-admin-controls';
import LoadingIndicator from '../../../components/loading-indicator/loading-indicator';
import * as actions from '../../../store/actions/index';
import './course.scss';

const Course = ({
  match,
  onGetCourseVideosFromSection,
  currentSectionVideos,
  loading,
  authLoading,
  onCheckAuth,
  userLevel,
  token,
  userWatchedVideos,
  onGetUserWatchedVideos,
  userID,
  error,
}) => {
  const sectionCopy = {
    misc: `This section addresses topics on safety (e.g., tapping, break falls), belt tying, and academy etiquette.`,
    solo: `This section addresses basic, fundamental movements that are frequently used during training/sparing; students can drill these core movements without a partner—a fun way to develop mastery, and improve fitness, flexibility, and strength.`,
    drills: `This section addresses movement sequences that are commonly used during sparring; these movements are more complex than those listed in the Solo Movements section, and generally require a partner or grappling dummy to drill with.`,
    techniques: `This ever-expanding/evolving section addresses specific techniques that students can use to control, escape, sweep, submit, and pass their opponents.  Over time and with experience, students will select and retain the techniques that work best for their body type and ability, thus developing their own Jiu Jitsu “game”.`,
  };

  // State
  const [deleteMode, setDeleteMode] = useState(false);

  useEffect(() => {
    onCheckAuth();
    if (token) {
      // get course videos for this section
      onGetCourseVideosFromSection(
        token,
        match.params.courseSection.split('-').join(' ')
      );
      onGetUserWatchedVideos(token, userID);
    }
  }, [token !== null]);

  const toggleDeleteMode = () => {
    setDeleteMode(!deleteMode);
  };

  const courseSection = match.params.courseSection.split('-').join(' ');

  let adminStatus = false;
  if (userLevel === 'admin') {
    adminStatus = true;
  }

  let listContent = null;
  if (loading) {
    listContent = (
      <LoadingIndicator
        loadingText={`Loading ${courseSection.toUpperCase()} Video List...`}
      />
    );
  } else if (!loading && currentSectionVideos.length > 0 && !error) {
    listContent = (
      <>
        <CourseAdminControls
          adminStatus={adminStatus}
          videoList={true}
          deleteMode={deleteMode}
          toggleDeleteMode={toggleDeleteMode}
          courseSection={match.params.courseSection}
        />
        <CourseLessonList
          courseData={currentSectionVideos}
          deleteMode={deleteMode}
          adminStatus={adminStatus}
          userWatchedVideos={userWatchedVideos}
        />
      </>
    );
  } else if (!loading && error) {
    listContent = (
      <>
        <CourseAdminControls
          adminStatus={adminStatus}
          videoList={true}
          deleteMode={deleteMode}
          toggleDeleteMode={toggleDeleteMode}
          courseSection={match.params.courseSection}
        />
        <h3 className='auth-error-text'>
          Could not authenticate your access to the Ultra Online library. Please
          log out and log back in to refresh your access.
        </h3>
      </>
    );
  } else if (!loading && currentSectionVideos.length < 1) {
    listContent = (
      <>
        <CourseAdminControls
          adminStatus={adminStatus}
          videoList={true}
          deleteMode={deleteMode}
          toggleDeleteMode={toggleDeleteMode}
          courseSection={match.params.courseSection}
        />
        <h3 className='no-videos-found-text'>
          No Videos Found For This Course.
        </h3>
      </>
    );
  }

  let progressBar = (
    <>
      <p className='completion-bar-text'>Course Completion Bar</p>
      <p className='completion-bar-percent'>0% of the course completed.</p>
      <ProgressBar className='completion-bar' animated now={0} />
    </>
  );

  if (userWatchedVideos) {
    if (userWatchedVideos.length > 0) {
      let currentSectionVideosID = currentSectionVideos.map(
        (course) => course.id
      );

      let videosWatchedInCollection = userWatchedVideos.filter((video) => {
        if (currentSectionVideosID.includes(video.video_id)) {
          return video.video_id;
        }
      });
      let progressBarValue =
        (videosWatchedInCollection.length / currentSectionVideosID.length) *
        100;
      progressBarValue = progressBarValue.toFixed(0);
      progressBar = (
        <>
          <p className='completion-bar-text'>Course Completion Bar</p>
          <p className='completion-bar-percent'>
            {progressBarValue}% of the course completed.
          </p>
          <ProgressBar
            className='completion-bar'
            animated
            now={progressBarValue}
          />
        </>
      );
    }
  }

  let sectionCopyComponent = null;
  if (courseSection === 'miscellaneous') {
    sectionCopyComponent = sectionCopy.misc;
  } else if (courseSection === 'solo movements') {
    sectionCopyComponent = sectionCopy.solo;
  } else if (courseSection === 'drills') {
    sectionCopyComponent = sectionCopy.drills;
  } else if (courseSection === 'techniques') {
    sectionCopyComponent = sectionCopy.techniques;
  } else {
    sectionCopyComponent = 'none';
  }

  let mainContent = null;
  if (authLoading) {
    mainContent = <LoadingIndicator loadingText='Authenticating User' />;
  } else if (sectionCopyComponent === 'none') {
    mainContent = (
      <>
        <div className='main-content'>
          <h3 className='no-course-msg'>
            Could not find specified course section.
          </h3>
        </div>
      </>
    );
  } else {
    mainContent = (
      <>
        <CourseAdminControls adminStatus={adminStatus} />
        {progressBar}
        <div className='main-content'>
          <div className='course-details'>
            <h2 className='course-title'>{courseSection}</h2>
            <p className='course-desc'>{sectionCopyComponent}</p>
            <Link
              className='course-link-back'
              to={{
                pathname: '/',
              }}
            >
              <p className='course-link-back-text'>Return Home</p>
            </Link>
          </div>
          <div className='course-video-list'>{listContent}</div>
        </div>
      </>
    );
  }
  return <div className='course-container'>{mainContent}</div>;
};

const mapStateToProps = (state) => {
  return {
    allCourseVideos: state.course.allCourseVideos,
    currentSectionVideos: state.course.currentSectionVideos,
    specificVideo: state.course.specificVideo,
    error: state.course.error,
    loading: state.course.loading,
    authLoading: state.auth.loading,
    userLevel: state.auth.userLevel,
    token: state.auth.token,
    userWatchedVideos: state.course.userWatchedVideos,
    userID: state.auth.userID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCourseVideosFromSection: (token, courseSection) =>
      dispatch(actions.getAllCourseVideosFromSection(token, courseSection)),
    onCheckAuth: (username, referrer) =>
      dispatch(actions.checkAuth(username, referrer)),
    onLogout: () => actions.logout(),
    onGetUserWatchedVideos: (token, userID) =>
      dispatch(actions.getUserWatchedVideos(token, userID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Course);
