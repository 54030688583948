import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import * as actions from '../../../store/actions/index';
import './course-video.scss';
import CourseLessonList from '../../../components/course-lesson-list/course-lesson-list';
import LoadingIndicator from '../../../components/loading-indicator/loading-indicator';
import ErrorDisplay from '../../../components/error-display/error-display';

const CourseVideo = ({
  match,
  onGetCourseVideosFromSection,
  onGetCourseVideo,
  currentSectionVideos,
  specificVideo,
  specificVideoId,
  loading,
  onCheckAuth,
  token,
  onStoreUserWatchedVideo,
  userID,
  userWatchedVideos,
  onGetUserWatchedVideos,
  error,
}) => {
  useEffect(() => {
    onCheckAuth();
    if (token) {
      //get course videos for this section
      onGetCourseVideosFromSection(token, match.params.courseSection);
      onGetCourseVideo(token, match.params.id);
      onGetUserWatchedVideos(token, userID);
    }
  }, [token, specificVideoId == match.params.id]);

  const onVideoEnded = () => {
    onStoreUserWatchedVideo(token, userID, match.params.id);
    onGetUserWatchedVideos(token, userID);
  };

  let pageContent = null;

  if (!loading && specificVideo !== null) {
    const {
      vimeo_url,
      course_section,
      course_topic,
      lesson_name,
      description,
      id,
    } = specificVideo;
    pageContent = (
      <div className='course-video-container'>
        <div className='player-wrapper'>
          <ReactPlayer
            url={vimeo_url}
            className='react-player'
            playing={false}
            controls={true}
            playsinline={true}
            onEnded={() => onVideoEnded()}
          />
        </div>

        <div className='lesson-container'>
          <div className='lesson-detail-container'>
            <p className='lesson-sub-text'>
              {course_section} - {course_topic}
            </p>
            <h3 className='lesson-name'>{lesson_name}</h3>
            {/* <p className='lesson-date-created'>{created_at}</p> */}
            <p className='lesson-desc'>{description}</p>
            <Link
              className='lesson-link-back'
              to={{
                pathname: `/course/${course_section.toLowerCase()}`,
              }}
            >
              <button className='lesson-link-back-text'>
                Return To {course_section} Page
              </button>
            </Link>
          </div>
          <div className='course-list-container'>
            <CourseLessonList
              courseData={currentSectionVideos}
              currentLessonName={lesson_name}
              currentLessonId={id}
              userWatchedVideos={userWatchedVideos}
            />
          </div>
        </div>
      </div>
    );
  } else if (loading && specificVideo === null) {
    pageContent = <LoadingIndicator />;
  } else if (!loading && error) {
    pageContent = <ErrorDisplay message={error.msg} display='normal' />;
  }
  return <> {pageContent}</>;
};
const mapStateToProps = (state) => {
  return {
    allCourseVideos: state.course.allCourseVideos,
    currentSectionVideos: state.course.currentSectionVideos,
    specificVideo: state.course.specificVideo,
    specificVideoId: state.course.specificVideoId,
    error: state.course.error,
    loading: state.course.loading,
    token: state.auth.token,
    userID: state.auth.userID,
    userWatchedVideos: state.course.userWatchedVideos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCourseVideosFromSection: (token, courseSection) =>
      dispatch(actions.getAllCourseVideosFromSection(token, courseSection)),
    onGetCourseVideo: (token, id) =>
      dispatch(actions.getCourseVideo(token, id)),
    onCheckAuth: (username, referrer) =>
      dispatch(actions.checkAuth(username, referrer)),
    onLogout: () => actions.logout(),
    onStoreUserWatchedVideo: (token, userID, videoID) =>
      dispatch(actions.storeUserWatchedVideo(token, userID, videoID)),
    onGetUserWatchedVideos: (token, userID) =>
      dispatch(actions.getUserWatchedVideos(token, userID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseVideo);
