import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import './edit-lesson-form.scss';

import LoadingIndicator from '../../loading-indicator/loading-indicator';

const EditLessonForm = ({
  courseSection,
  onUpdateCourseVideo,
  loading,
  selectedCourseData,
  handleClose,
  selectedLessonId,
  onLogout,
  allCourseData,
}) => {
  const storedToken = localStorage.getItem('token');
  if (!storedToken) {
    onLogout();
  }
  const [courseTopic, setCourseTopic] = useState('');
  const [prevCourseTopic, setPrevCourseTopic] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [description, setDescription] = useState('');
  const [vimeoUrl, setVimeoUrl] = useState('');

  useEffect(() => {
    setPrevCourseTopic(selectedCourseData.course_topic);
    setCourseTopic(selectedCourseData.course_topic);
    setLessonName(selectedCourseData.lesson_name);
    setDescription(selectedCourseData.description);
    setVimeoUrl(selectedCourseData.vimeo_url);
  }, []);
  /*
    Form Check List
    Course Topic: text input
    Lesson Name: text input
    Description: textarea
    Vimeo URL: text input
    Belt Level: radio buttons
    */

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'course-topic') {
      setCourseTopic(value);
    } else if (name === 'lesson-name') {
      setLessonName(value);
    } else if (name === 'description') {
      setDescription(value);
    } else if (name === 'vimeo-url') {
      setVimeoUrl(value);
    }
  };

  const onFormSubmit = () => {
    let newLessonData = {
      course_section: courseSection.split('-').join(' '),
      course_topic: courseTopic,
      prev_course_topic: prevCourseTopic,
      lesson_name: lessonName,
      description: description,
      vimeo_url: vimeoUrl,
    };
    onUpdateCourseVideo(storedToken, selectedLessonId, newLessonData);
  };
  let content;
  if (loading) {
    content = <LoadingIndicator />;
  } else {
    content = (
      <>
        <label className='form-label'>Course Topic</label>
        <input
          type='text'
          placeholder='Course Topic'
          name='course-topic'
          value={courseTopic}
          onChange={handleChange}
          required
        />
        {/* <Autocomplete
          options={allCourseData}
          getOptionLabel={(video) => video.course_topic}
          renderInput={(params) => (
            <TextField {...params} label='Combo box' variant='outlined' />
          )}
        /> */}
        <label className='form-label'>Lesson Name</label>
        <input
          type='text'
          placeholder='Lesson Name'
          name='lesson-name'
          value={lessonName}
          onChange={handleChange}
          required
        />
        <label className='form-label'>Description</label>
        <textarea
          type='text'
          name='description'
          placeholder='Description'
          rows='7'
          value={description}
          onChange={handleChange}
          required
        />
        <label className='form-label'>Vimeo URL</label>
        <input
          type='url'
          placeholder='https://vimeo.com/#########'
          name='vimeo-url'
          value={vimeoUrl}
          onChange={handleChange}
          required
        />
        <div className='edit-lesson-btn-container'>
          <button
            className='edit-btn edit-yes'
            type='submit'
            onClick={onFormSubmit}
          >
            Update
          </button>
          <button className='edit-btn edit-no' onClick={handleClose}>
            Cancel
          </button>
        </div>
      </>
    );
  }
  return <div className='edit-lesson-form'>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    error: state.course.error,
    loading: state.course.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCourseVideo: (token, id, courseSection) =>
      dispatch(actions.updateCourseVideo(token, id, courseSection)),
    onLogout: () => actions.logout(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditLessonForm);
