import React from 'react';
import { Modal } from 'react-bootstrap';
import './edit-lesson-popup.scss';
import EditLessonForm from '../../forms/edit-lesson-form/edit-lesson-form';

const EditLessonPopup = ({
  show,
  handleClose,
  courseSection,
  selectedLessonId,
  selectedCourseData,
  allCourseData,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} className='edit-lesson-popup'>
        <Modal.Body className='modal-body'>
          <h3 className='edit-lesson-name'>
            {courseSection}: Edit Lesson Form
          </h3>
          <div className='form-container'>
            <EditLessonForm
              courseSection={courseSection}
              selectedLessonId={selectedLessonId}
              selectedCourseData={selectedCourseData}
              handleClose={handleClose}
              allCourseData={allCourseData}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditLessonPopup;
