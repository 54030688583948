import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import * as actions from '../../store/actions/index';

import {
  faTrashAlt,
  faPencilAlt,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import './course-lesson-list.scss';

import DeleteLessonPopup from '../popups/delete-lesson-popup/delete-lesson-popup';
import EditLessonPopup from '../popups/edit-lesson-popup/edit-lesson-popup';

const CourseLessonList = ({
  courseData,
  currentLessonName,
  currentLessonId,
  deleteMode,
  adminStatus,
  onUpdateCourseOrder,
  onLogout,
  userWatchedVideos,
}) => {
  const storedToken = localStorage.getItem('token');
  if (!storedToken) {
    onLogout();
  }

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedLessonId, setSelectedLessonId] = useState(-1);
  const [currentCourseSection, setCurrentCourseSection] = useState();
  const [selectedCourseData, setSelectedCourseData] = useState();

  const handleClose = () => setShow(false);

  const handleShow = (lessonId, courseSection) => {
    setSelectedLessonId(lessonId);
    setCurrentCourseSection(courseSection);
    setShow(true);
  };

  const handleCloseEdit = () => setShowEdit(false);

  const handleShowEdit = (lessonId, courseSection, selectedCourse) => {
    setSelectedLessonId(lessonId);
    setCurrentCourseSection(courseSection);
    setSelectedCourseData(selectedCourse);
    setShowEdit(true);
  };
  // Edit Button
  let editBtn = () => {
    return null;
  };

  if (adminStatus) {
    editBtn = (id, selectedCourse) => {
      return (
        <span
          className='edit-lesson-text'
          onClick={() =>
            handleShowEdit(id, selectedCourse.course_section, selectedCourse)
          }
        >
          {' '}
          <FontAwesomeIcon icon={faPencilAlt} className='icon' />
          &nbsp;Edit
        </span>
      );
    };
  }

  // Delete Button
  let deleteBtn = () => {
    return null;
  };
  if (deleteMode) {
    deleteBtn = (id, courseSection) => {
      return (
        <span
          className='delete-lesson-text'
          onClick={() => handleShow(id, courseSection)}
        >
          {' '}
          <FontAwesomeIcon icon={faTrashAlt} className='icon' />
          &nbsp;Delete
        </span>
      );
    };
  }

  // On Adjust Topic Order Up Pressed
  const adjustTopicOrderUpPressed = (
    courseSection,
    courseTopic,
    lessonName,
    currentOrder
  ) => {
    let requestData = {
      action: 'move-up',
      group: 'topic',
      name: lessonName,
      topic: courseTopic,
      section: courseSection,
      currentOrder: currentOrder,
    };
    onUpdateCourseOrder(storedToken, requestData);
  };

  // Adjust Lesson Order Up Button
  let adjustTopicOrderUpBtn = () => {
    return null;
  };

  // On Adjust Lesson Order Up Pressed
  const adjustLessonOrderUpPressed = (
    courseSection,
    courseTopic,
    lessonName,
    currentOrder
  ) => {
    let requestData = {
      action: 'move-up',
      group: 'lesson',
      name: lessonName,
      topic: courseTopic,
      section: courseSection,
      currentOrder: currentOrder,
    };
    onUpdateCourseOrder(storedToken, requestData);
  };

  // Adjust Lesson Order Up Button
  let adjustLessonOrderUpBtn = () => {
    return null;
  };

  if (adminStatus) {
    // Lesson
    adjustLessonOrderUpBtn = (
      courseSection,
      courseTopic,
      lessonName,
      currentOrder
    ) => {
      if (currentOrder === 1) {
        return null;
      } else {
        return (
          <span>
            <FontAwesomeIcon
              icon={faAngleUp}
              className='icon order-arrow-icon'
              onClick={() =>
                adjustLessonOrderUpPressed(
                  courseSection,
                  courseTopic,
                  lessonName,
                  currentOrder
                )
              }
            />
          </span>
        );
      }
    };
    // Topic
    adjustTopicOrderUpBtn = (
      courseSection,
      courseTopic,
      lessonName,
      currentOrder
    ) => {
      if (currentOrder === 1) {
        return null;
      } else {
        return (
          <span>
            <FontAwesomeIcon
              icon={faAngleUp}
              className='icon order-arrow-icon'
              onClick={() =>
                adjustTopicOrderUpPressed(
                  courseSection,
                  courseTopic,
                  lessonName,
                  currentOrder
                )
              }
            />
          </span>
        );
      }
    };
  }

  // On Adjust Topic Order Down Pressed
  const adjustTopicOrderDownPressed = (
    courseSection,
    courseTopic,
    lessonName,
    currentOrder
  ) => {
    let requestData = {
      action: 'move-down',
      group: 'topic',
      name: lessonName,
      topic: courseTopic,
      section: courseSection,
      currentOrder: currentOrder,
    };
    onUpdateCourseOrder(storedToken, requestData);
  };

  // Adjust Lesson Order Down Button
  let adjustTopicOrderDownBtn = () => {
    return null;
  };

  // On Adjust Lesson Order Down Pressed
  const adjustLessonOrderDownPressed = (
    courseSection,
    courseTopic,
    lessonName,
    currentOrder
  ) => {
    let requestData = {
      action: 'move-down',
      group: 'lesson',
      name: lessonName,
      topic: courseTopic,
      section: courseSection,
      currentOrder: currentOrder,
    };
    onUpdateCourseOrder(storedToken, requestData);
  };

  // Adjust Lesson Order Down Button
  let adjustLessonOrderDownBtn = () => {
    return null;
  };

  if (adminStatus) {
    // Lesson
    adjustLessonOrderDownBtn = (
      courseSection,
      courseTopic,
      lessonName,
      currentOrder,
      courseData
    ) => {
      let filteredDataByTopic = courseData.filter((course) => {
        return courseTopic === course.course_topic;
      });

      if (filteredDataByTopic.length === currentOrder) {
        return null;
      } else {
        return (
          <span>
            <FontAwesomeIcon
              icon={faAngleDown}
              className='icon order-arrow-icon'
              onClick={() =>
                adjustLessonOrderDownPressed(
                  courseSection,
                  courseTopic,
                  lessonName,
                  currentOrder
                )
              }
            />
          </span>
        );
      }
    };
    // Topic
    adjustTopicOrderDownBtn = (
      courseSection,
      courseTopic,
      lessonName,
      currentOrder,
      courseData
    ) => {
      // Check how many unique topics are there
      let uniqueTopics = [];
      for (let i = 0; i < courseData.length; i++) {
        if (!uniqueTopics.includes(courseData[i].course_topic)) {
          uniqueTopics.push(courseData[i].course_topic);
        }
      }

      if (uniqueTopics.length === currentOrder) {
        // hide the last down button
        return null;
      } else {
        return (
          <span>
            <FontAwesomeIcon
              icon={faAngleDown}
              className='icon order-arrow-icon'
              onClick={() =>
                adjustTopicOrderDownPressed(
                  courseSection,
                  courseTopic,
                  lessonName,
                  currentOrder
                )
              }
            />
          </span>
        );
      }
    };
  }

  // Course Video
  let couseVideoWatchedIcon = (videoID) => {
    if (videoID) {
      let videoContains = userWatchedVideos.filter(
        (data) => data.video_id === videoID
      );
      if (videoContains.length > 0) {
        return (
          <FontAwesomeIcon icon={faCheckCircle} className='icon watched' />
        );
      }
    }
    return <FontAwesomeIcon icon={faCircle} className='icon' />;
  };
  let uCourseTopicStore = [];
  let selectedCourse;
  let courseAccordionBar = courseData.map(
    ({
      id,
      lesson_name,
      course_section,
      course_topic,
      vimeo_url,
      description,
      lesson_order,
      topic_order,
    }) => {
      selectedCourse = {
        id,
        lesson_name,
        course_section,
        course_topic,
        vimeo_url,
        description,
        lesson_order,
        topic_order,
      };
      if (!uCourseTopicStore.includes(course_topic)) {
        uCourseTopicStore.push(course_topic);
        if (!currentLessonName) {
          return (
            <span key={`${id}${course_section}${course_topic}${lesson_name}`}>
              <div className='course-heading-row'>
                <h4 className='course-list-topic-heading'>{course_topic}</h4>
                <div className='adjust-topic-order-btn-container'>
                  {adjustTopicOrderUpBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    topic_order
                  )}
                  {adjustTopicOrderDownBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    topic_order,
                    courseData
                  )}
                </div>
              </div>
              <div className='course-list-row'>
                <div className='adjust-lesson-order-btn-conatiner'>
                  {adjustLessonOrderUpBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    lesson_order
                  )}
                  {adjustLessonOrderDownBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    lesson_order,
                    courseData
                  )}
                </div>
                <div className='course-list-lesson-container'>
                  <Link
                    className='course-list-link'
                    to={{
                      pathname: `/course/${course_section.toLowerCase()}/${id}`,
                    }}
                  >
                    <p className='course-list-lesson'>
                      {couseVideoWatchedIcon(id)}
                      {lesson_name}
                    </p>
                  </Link>
                  <div className='delete-edit-btn-container'>
                    {deleteBtn(id, course_section)}
                    {editBtn(id, selectedCourse)}
                  </div>
                </div>
              </div>
            </span>
          );
        } else if (
          currentLessonName === lesson_name &&
          currentLessonId === id
        ) {
          return (
            <span key={`${id}${course_section}${course_topic}${lesson_name}`}>
              <div className='course-heading-row'>
                <h4 className='course-list-topic-heading'>{course_topic}</h4>
                <div className='adjust-topic-order-btn-container'>
                  {adjustTopicOrderUpBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    topic_order
                  )}
                  {adjustTopicOrderDownBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    topic_order,
                    courseData
                  )}
                </div>
              </div>
              <div className='course-list-row'>
                <div className='adjust-lesson-order-btn-conatiner'>
                  {adjustLessonOrderUpBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    lesson_order
                  )}
                  {adjustLessonOrderDownBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    lesson_order,
                    courseData
                  )}
                </div>
                <div className='course-list-lesson-container current'>
                  <Link
                    className='course-list-link'
                    to={{
                      pathname: `/course/${course_section.toLowerCase()}/${id}`,
                    }}
                  >
                    <p className='course-list-lesson'>
                      {couseVideoWatchedIcon(id)}
                      {lesson_name}
                    </p>
                  </Link>
                  <div className='delete-edit-btn-container'>
                    {deleteBtn(id, course_section)}
                    {editBtn(id, selectedCourse)}
                  </div>
                </div>
              </div>
            </span>
          );
        } else {
          return (
            <span key={`${id}${course_section}${course_topic}${lesson_name}`}>
              <div className='course-heading-row'>
                <h4 className='course-list-topic-heading'>{course_topic}</h4>
                <div className='adjust-topic-order-btn-container'>
                  {adjustTopicOrderUpBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    topic_order
                  )}
                  {adjustTopicOrderDownBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    topic_order,
                    courseData
                  )}
                </div>
              </div>
              <div className='course-list-row'>
                <div className='adjust-lesson-order-btn-conatiner'>
                  {adjustLessonOrderUpBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    lesson_order
                  )}
                  {adjustLessonOrderDownBtn(
                    course_section,
                    course_topic,
                    lesson_name,
                    lesson_order,
                    courseData
                  )}
                </div>
                <div className='course-list-lesson-container'>
                  <Link
                    className='course-list-link'
                    to={{
                      pathname: `/course/${course_section.toLowerCase()}/${id}`,
                    }}
                  >
                    <p className='course-list-lesson'>
                      {couseVideoWatchedIcon(id)}
                      {lesson_name}
                    </p>
                  </Link>
                  <div className='delete-edit-btn-container'>
                    {deleteBtn(id, course_section)}
                    {editBtn(id, selectedCourse)}
                  </div>
                </div>
              </div>
            </span>
          );
        }
      } else {
        if (!currentLessonName) {
          return (
            <div
              className='course-list-row'
              key={`${id}${course_section}${course_topic}${lesson_name}`}
            >
              <div className='adjust-lesson-order-btn-conatiner'>
                {adjustLessonOrderUpBtn(
                  course_section,
                  course_topic,
                  lesson_name,
                  lesson_order
                )}
                {adjustLessonOrderDownBtn(
                  course_section,
                  course_topic,
                  lesson_name,
                  lesson_order,
                  courseData
                )}
              </div>
              <div
                className='course-list-lesson-container'
                key={`${id}${course_section}${course_topic}${lesson_name}`}
              >
                <Link
                  className='course-list-link'
                  to={{
                    pathname: `/course/${course_section.toLowerCase()}/${id}`,
                  }}
                >
                  <p className='course-list-lesson'>
                    {couseVideoWatchedIcon(id)}
                    {lesson_name}
                  </p>
                </Link>
                <div className='delete-edit-btn-container'>
                  {deleteBtn(id, course_section)}
                  {editBtn(id, selectedCourse)}
                </div>
              </div>
            </div>
          );
        } else if (
          currentLessonName === lesson_name &&
          currentLessonId === id
        ) {
          return (
            <div
              className='course-list-row'
              key={`${id}${course_section}${course_topic}${lesson_name}`}
            >
              <div className='adjust-lesson-order-btn-conatiner'>
                {adjustLessonOrderUpBtn(
                  course_section,
                  course_topic,
                  lesson_name,
                  lesson_order
                )}
                {adjustLessonOrderDownBtn(
                  course_section,
                  course_topic,
                  lesson_name,
                  lesson_order,
                  courseData
                )}
              </div>
              <div
                className='course-list-lesson-container current'
                key={`${id}${course_section}${course_topic}${lesson_name}`}
              >
                <Link
                  className='course-list-link'
                  to={{
                    pathname: `/course/${course_section.toLowerCase()}/${id}`,
                  }}
                >
                  <p className='course-list-lesson'>
                    {couseVideoWatchedIcon(id)}
                    {lesson_name}
                  </p>
                </Link>
                <div className='delete-edit-btn-container'>
                  {deleteBtn(id, course_section)}
                  {editBtn(id, selectedCourse)}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              className='course-list-row'
              key={`${id}${course_section}${course_topic}${lesson_name}`}
            >
              <div className='adjust-lesson-order-btn-conatiner'>
                {adjustLessonOrderUpBtn(
                  course_section,
                  course_topic,
                  lesson_name,
                  lesson_order
                )}
                {adjustLessonOrderDownBtn(
                  course_section,
                  course_topic,
                  lesson_name,
                  lesson_order,
                  courseData
                )}
              </div>
              <div
                className='course-list-lesson-container'
                key={`${id}${course_section}${course_topic}${lesson_name}`}
              >
                <Link
                  className='course-list-link'
                  to={{
                    pathname: `/course/${course_section.toLowerCase()}/${id}`,
                  }}
                >
                  <p className='course-list-lesson'>
                    {couseVideoWatchedIcon(id)}
                    {lesson_name}
                  </p>
                </Link>
                <div className='delete-edit-btn-container'>
                  {deleteBtn(id, course_section)}
                  {editBtn(id, selectedCourse)}
                </div>
              </div>
            </div>
          );
        }
      }
    }
  );

  let uCourseTopicObj = uCourseTopicStore.map((video, index) => {
    if (uCourseTopicStore.indexOf(video) === index) {
      return { course_topic: video };
    }
  });

  return (
    <div className='course-list-container'>
      {courseAccordionBar}
      <DeleteLessonPopup
        handleClose={handleClose}
        show={show}
        selectedLessonId={selectedLessonId}
        currentCourseSection={currentCourseSection}
      />
      <EditLessonPopup
        handleClose={handleCloseEdit}
        show={showEdit}
        selectedLessonId={selectedLessonId}
        courseSection={currentCourseSection}
        selectedCourseData={selectedCourseData}
        allCourseData={uCourseTopicObj}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.course.error,
    loading: state.course.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCourseOrder: (token, requestData) =>
      dispatch(actions.updateCourseOrder(token, requestData)),
    onLogout: () => actions.logout(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseLessonList);
