import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  userID: null,
  username: null,
  userLevel: null,
  error: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.token,
        userID: action.userID,
        userLevel: action.userLevel,
        username: action.username,
      };
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        userID: null,
        username: null,
        userLevel: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
