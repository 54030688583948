import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import './all-courses.scss';
import CourseCards from '../../../components/course-cards/course-cards';
import AllCourseVideoAdmin from '../../../components/admin/all-course-video-admin/all-course-video-admin';
import LoadingIndicator from '../../../components/loading-indicator/loading-indicator';
import * as actions from '../../../store/actions/index';
import COURSES_DATA from './all-courses.data';

const AllCourses = ({ location, onCheckAuth, loading, userLevel }) => {
  let adminStatus = false;
  if (userLevel === 'admin') {
    adminStatus = true;
  }
  // Check if user is authenticated
  useEffect(() => {
    const params = queryString.parse(location.search);
    // console.log(document.referrer);
    onCheckAuth(params.username, document.referrer);
  }, []);

  let content = <LoadingIndicator loadingText='Authenticating User' />;

  if (!loading) {
    content = (
      <>
        <AllCourseVideoAdmin adminStatus={adminStatus} />
        <h2>Welcome To Ultra Online!</h2>
        <p className='all-course-paragraph'>
          Our lessons are separated into sections for your convenience. Click on
          "Course Overview" to see what topics are covered in each section.
        </p>
        <CourseCards COURSES_DATA={COURSES_DATA} />
      </>
    );
  }
  return <div className='all-courses-page'>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    token: state.auth.token,
    userLevel: state.auth.userLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckAuth: (username, referrer) =>
      dispatch(actions.checkAuth(username, referrer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCourses);
