import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';

// navigation bar
import NavigationBar from './components/navigation-bar/navigation-bar';
// footer bar
import FooterBar from './components/footer-bar/footer-bar';

// Authentication Page (Zen Planner Remote Login Redirect);
import Authentication from './pages/authentication/authentication';

// all courses page
import AllCourses from './pages/courses/all-courses/all-courses';
// specific course page
import Course from './pages/courses/course/course';
// specific course video page
import CourseVideo from './pages/courses/course-video/course-video';
// course gallery for terms
import CourseGallery from './pages/courses/course-gallery/course-gallery';

const App = () => {
  return (
    <>
      <ScrollToTop />
      <NavigationBar />
      <div className='app'>
        <Switch>
          <Route exact path='/' component={AllCourses} />

          <Route exact path='/course/terms' component={CourseGallery} />
          <Route path='/course/:courseSection/:id' component={CourseVideo} />
          <Route path='/course/:courseSection' component={Course} />

          <Route exact path='/auth-zen-planner' component={Authentication} />
        </Switch>
      </div>
      <FooterBar />
    </>
  );
};

export default App;
