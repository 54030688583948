const TERM_IMAGES = [
  {
    id: 1,
    name: 'Collar',
    imageLinks: [
      {
        name: '1 Collar',
        src: '1 Collar.jpg',
      },
    ],
  },
  {
    id: 2,
    name: 'Cuff',
    imageLinks: [
      {
        name: '2 Cuff Wrist',
        src: '2 Cuff Wrist.jpg',
      },
      {
        name: '2a Cuff Ankle',
        src: '2a Cuff Ankle.jpg',
      },
      {
        name: '2b Cuff Ankle',
        src: '2b Cuff Ankle.jpg',
      },
    ],
  },
  {
    id: 3,
    name: 'Lapel',
    imageLinks: [
      {
        name: '3 Lapel',
        src: '3 Lapel.jpg',
      },
    ],
  },
  {
    id: 4,
    name: 'Underhook',
    imageLinks: [
      {
        name: '4 Underhook',
        src: '4 Underhook.jpg',
      },
      {
        name: '4a Underhook',
        src: '4a Underhook.jpg',
      },
    ],
  },
  {
    id: 5,
    name: 'Overhook',
    imageLinks: [
      {
        name: '5 Overhook',
        src: '5 Overhook.jpg',
      },
      {
        name: '5a Overhook',
        src: '5a Overhook.jpg',
      },
    ],
  },
  {
    id: 6,
    name: 'Cross Grip',
    imageLinks: [
      {
        name: '6 Cross Grip',
        src: '6 Cross Grip.jpg',
      },
    ],
  },
  {
    id: 7,
    name: 'Same Side Grip',
    imageLinks: [
      {
        name: '7 Same Side Grip',
        src: '7 Same Side Grip.jpg',
      },
    ],
  },
  {
    id: 8,
    name: 'Pistol Grip',
    imageLinks: [
      {
        name: '8 Pistol Grip',
        src: '8 Pistol Grip.jpg',
      },
      {
        name: '8a Pistol Grip',
        src: '8a Pistol Grip.jpg',
      },
    ],
  },
  {
    id: 9,
    name: "Cat's Paw Grip (Pocket Grip)",
    imageLinks: [
      {
        name: "9 Cat's Paw Grip (Pocket Grip)",
        src: "9 Cat's Paw Grip (Pocket Grip).jpg",
      },
    ],
  },
  {
    id: 10,
    name: 'Horse Collar Grip',
    imageLinks: [
      {
        name: '10 Horse Collar Grip',
        src: '10 Horse Collar Grip.jpg',
      },
    ],
  },
  {
    id: 11,
    name: 'Lapel Grip',
    imageLinks: [
      {
        name: '11 Lapel Grip',
        src: '11 Lapel Grip.jpg',
      },
    ],
  },
  {
    id: 12,
    name: 'Belt Grip',
    imageLinks: [
      {
        name: '12 Belt Grip',
        src: '12 Belt Grip.jpg',
      },
      {
        name: '12a Belt Grip',
        src: '12a Belt Grip.jpg',
      },
    ],
  },
  {
    id: 13,
    name: 'Illegal Grip',
    imageLinks: [
      {
        name: '13 Illegal Grip',
        src: '13 Illegal Grip.jpg',
      },
      {
        name: '13a Illegal Grip',
        src: '13a Illegal Grip.jpg',
      },
      {
        name: '13b Illegal Grip',
        src: '13b Illegal Grip.jpg',
      },
    ],
  },
  {
    id: 14,
    name: 'Seat Belt',
    imageLinks: [
      {
        name: '14 Seat Belt',
        src: '14 Seat Belt.jpg',
      },
    ],
  },
  {
    id: 15,
    name: 'Hooks',
    imageLinks: [
      {
        name: '15 Hooks',
        src: '15 Hooks.jpg',
      },
    ],
  },
  {
    id: 16,
    name: 'Backpack',
    imageLinks: [
      {
        name: '16 Backpack',
        src: '16 Backpack.jpg',
      },
    ],
  },
  {
    id: 17,
    name: 'Crossface',
    imageLinks: [
      {
        name: '17 Crossface',
        src: '17 Crossface.jpg',
      },
      {
        name: '17a Crossface',
        src: '17a Crossface.jpg',
      },
      {
        name: '17b Crossface',
        src: '17b Crossface.jpg',
      },
    ],
  },
  {
    id: 18,
    name: 'Chin Strap',
    imageLinks: [
      {
        name: '18 Chin Strap',
        src: '18 Chin Strap.jpg',
      },
      {
        name: '18a Chin Strap',
        src: '18a Chin Strap.jpg',
      },
    ],
  },
  {
    id: 19,
    name: 'Whizzer',
    imageLinks: [
      {
        name: '19 Whizzer',
        src: '19 Whizzer.jpg',
      },
    ],
  },
  {
    id: 20,
    name: 'Stack',
    imageLinks: [
      {
        name: '20 Stack',
        src: '20 Stack.jpg',
      },
    ],
  },
  {
    id: 21,
    name: 'Sprawl',
    imageLinks: [
      {
        name: '21 Sprawl',
        src: '21 Sprawl.jpg',
      },
      {
        name: '21a Sprawl',
        src: '21a Sprawl.jpg',
      },
      {
        name: '21b Sprawl',
        src: '21b Sprawl.jpg',
      },
    ],
  },
  {
    id: 22,
    name: 'Combat Knee',
    imageLinks: [
      {
        name: '22 Combat Knee',
        src: '22 Combat Knee.jpg',
      },
      {
        name: '22a Combat Knee',
        src: '22a Combat Knee.jpg',
      },
    ],
  },
  {
    id: 23,
    name: 'Mount',
    imageLinks: [
      {
        name: '23 Mount',
        src: '23 Mount.jpg',
      },
      {
        name: '23a Mount',
        src: '23a Mount.jpg',
      },
    ],
  },
  {
    id: 24,
    name: 'Side Control',
    imageLinks: [
      {
        name: '24 Side Control',
        src: '24 Side Control.jpg',
      },
      {
        name: '24a Side Control',
        src: '24a Side Control.jpg',
      },
    ],
  },
  {
    id: 25,
    name: 'Closed Guard',
    imageLinks: [
      {
        name: '25 Closed Guard',
        src: '25 Closed Guard.jpg',
      },
      {
        name: '25a Closed Guard',
        src: '25a Closed Guard.jpg',
      },
      {
        name: '25b Closed Guard',
        src: '25b Closed Guard.jpg',
      },
    ],
  },
  {
    id: 26,
    name: 'Body Triangle',
    imageLinks: [
      {
        name: '26 Body Triangle',
        src: '26 Body Triangle.jpg',
      },
      {
        name: '26a Body Triangle',
        src: '26a Body Triangle.jpg',
      },
    ],
  },
  {
    id: 27,
    name: 'Half Guard',
    imageLinks: [
      {
        name: '27 Half Guard',
        src: '27 Half Guard.jpg',
      },
      {
        name: '27a Half Guard',
        src: '27a Half Guard.jpg',
      },
    ],
  },
  {
    id: 28,
    name: 'North South',
    imageLinks: [
      {
        name: '28 North South',
        src: '28 North South.jpg',
      },
      {
        name: '28a North South',
        src: '28a North South.jpg',
      },
    ],
  },
  {
    id: 29,
    name: 'Knee on Belly',
    imageLinks: [
      {
        name: '29 Knee on Belly',
        src: '29 Knee on Belly.jpg',
      },
      {
        name: '29a Knee on Belly',
        src: '29a Knee on Belly.jpg',
      },
    ],
  },
  {
    id: 30,
    name: 'Open Guard',
    imageLinks: [
      {
        name: '30 Open Guard',
        src: '30 Open Guard.jpg',
      },
      {
        name: '30a Open Guard',
        src: '30a Open Guard.jpg',
      },
    ],
  },
  {
    id: 31,
    name: '50-50',
    imageLinks: [
      {
        name: '31 50-50',
        src: '31 50-50.jpg',
      },
      {
        name: '31a 50-50',
        src: '31a 50-50.jpg',
      },
      {
        name: '31b 50-50',
        src: '31b 50-50.jpg',
      },
    ],
  },
  {
    id: 32,
    name: 'Turtle',
    imageLinks: [
      {
        name: '32 Turtle',
        src: '32 Turtle.jpg',
      },
      {
        name: '32a Turtle',
        src: '32a Turtle.jpg',
      },
    ],
  },
  {
    id: 33,
    name: 'Ready/Fighting Stance',
    imageLinks: [
      {
        name: '33 Ready/Fighting Stance',
        src: '33 Stand Up.jpg',
      },
      {
        name: '33a Ready/Fighting Stance',
        src: '33a Stand Up.jpg',
      },
      {
        name: '33b Ready/Fighting Stance',
        src: '33b Stand Up.jpg',
      },
    ],
  },
];

export default TERM_IMAGES;
