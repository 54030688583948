import axios from '../../axios-config';

import * as actionTypes from './actionTypes';
const { REACT_APP_REFERRER_CHECK_URL, REACT_APP_MAIN_SITE_URL } = process.env;

export const checkAuth = (username, referrer) => {
  return (dispatch) => {
    dispatch(authStart());
    const token = localStorage.getItem('token');
    const usernameStored = localStorage.getItem('username');
    if (!token) {
      if (referrer !== REACT_APP_REFERRER_CHECK_URL) {
        // Redirect to main website
        window.location.href = REACT_APP_MAIN_SITE_URL;
      } else if (referrer === REACT_APP_REFERRER_CHECK_URL) {
        axios
          .post(`/aut/${username}`)
          .then((res) => {
            let today = new Date();
            let expire = new Date();
            expire.setTime(today.getTime() + 3600000 * 24 * 14);
            localStorage.setItem('token', res.data[0].auth_token);
            localStorage.setItem('expirationDate', expire);
            localStorage.setItem('userID', res.data[0].id);
            localStorage.setItem('userLevel', res.data[0].user_level);
            localStorage.setItem('username', res.data[0].username);
            dispatch(
              authSuccess(
                res.data[0].auth_token,
                res.data[0].id,
                res.data[0].user_level,
                res.data[0].username
              )
            );
          })
          .catch((err) => {
            console.log(err);
            dispatch(authFail(err));
          });
      }
    } else {
      if (username) {
        if (usernameStored !== username) {
          axios
            .post(`/aut/${username}`)
            .then((res) => {
              let today = new Date();
              let expire = new Date();
              expire.setTime(today.getTime() + 3600000 * 24 * 14);
              localStorage.setItem('token', res.data[0].auth_token);
              localStorage.setItem('expirationDate', expire);
              localStorage.setItem('userID', res.data[0].id);
              localStorage.setItem('userLevel', res.data[0].user_level);
              localStorage.setItem('username', res.data[0].username);
              dispatch(
                authSuccess(
                  res.data[0].auth_token,
                  res.data[0].id,
                  res.data[0].user_level,
                  res.data[0].username
                )
              );
            })
            .catch((err) => {
              console.log(err);
              dispatch(authFail(err));
            });
        } else {
          const expirationDate = new Date(
            localStorage.getItem('expirationDate')
          );
          if (expirationDate <= new Date() && !username && !referrer) {
            // navigate to zen planner login
            // Redirect to main website
            window.location.href = REACT_APP_MAIN_SITE_URL;
          } else if (expirationDate <= new Date() && username && referrer) {
            axios
              .post(`/aut/${username}`)
              .then((res) => {
                let today = new Date();
                let expire = new Date();
                expire.setTime(today.getTime() + 3600000 * 24 * 14);
                localStorage.setItem('token', res.data[0].auth_token);
                localStorage.setItem('expirationDate', expire);
                localStorage.setItem('userID', res.data[0].id);
                localStorage.setItem('userLevel', res.data[0].user_level);
                localStorage.setItem('username', res.data[0].username);
                dispatch(
                  authSuccess(
                    res.data[0].auth_token,
                    res.data[0].id,
                    res.data[0].user_level,
                    res.data[0].username
                  )
                );
              })
              .catch((err) => {
                console.log(err);
                dispatch(authFail(err));
              });
          } else {
            const userId = localStorage.getItem('userID');
            const userLevel = localStorage.getItem('userLevel');
            dispatch(authSuccess(token, userId, userLevel, usernameStored));
            dispatch(
              checkAuthTimeout(
                (expirationDate.getTime() - new Date().getTime()) / 1000
              )
            );
          }
        }
      } else {
        const expirationDate = new Date(localStorage.getItem('expirationDate'));
        if (expirationDate <= new Date()) {
          // navigate to zen planner login
          // Redirect to main website
          window.location.href = REACT_APP_MAIN_SITE_URL;
        } else {
          const userId = localStorage.getItem('userID');
          const userLevel = localStorage.getItem('userLevel');
          dispatch(authSuccess(token, userId, userLevel, usernameStored));
          dispatch(
            checkAuthTimeout(
              (expirationDate.getTime() - new Date().getTime()) / 1000
            )
          );
        }
      }
    }
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};
export const authSuccess = (token, userID, userLevel, username) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userID: userID,
    userLevel: userLevel,
    username: username,
  };
};

export const authFail = (err) => {
  // Redirect to main website
  window.location.href = REACT_APP_MAIN_SITE_URL;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userID');
  localStorage.removeItem('userLevel');
  authStart();
  // Redirect to main website
  window.location.href = REACT_APP_MAIN_SITE_URL;
};

// checkAuthTimeout checks if the token has expired
export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};
