import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import './add-lesson-form.scss';

import LoadingIndicator from '../loading-indicator/loading-indicator';

const AddLessonForm = ({
  courseSection,
  onAddCourseVideo,
  loading,
  onLogout,
  handleClose,
}) => {
  const storedToken = localStorage.getItem('token');
  if (!storedToken) {
    onLogout();
  }
  const [courseTopic, setCourseTopic] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [description, setDescription] = useState('');
  const [vimeoUrl, setVimeoUrl] = useState('');
  /*
    Form Check List
    Course Topic: text input
    Lesson Name: text input
    Description: textarea
    Vimeo URL: text input
    Belt Level: radio buttons
    */
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'course-topic') {
      setCourseTopic(value);
    } else if (name === 'lesson-name') {
      setLessonName(value);
    } else if (name === 'description') {
      setDescription(value);
    } else if (name === 'vimeo-url') {
      setVimeoUrl(value);
    }
  };

  const onFormSubmit = () => {
    let newLessonData = {
      course_section: courseSection.split('-').join(' '),
      course_topic: courseTopic,
      lesson_name: lessonName,
      description: description,
      vimeo_url: vimeoUrl,
    };
    onAddCourseVideo(storedToken, newLessonData);
  };

  let content;
  if (loading) {
    content = <LoadingIndicator />;
  } else {
    content = (
      <>
        <label className='form-label'>Course Topic</label>
        <input
          type='text'
          placeholder='Course Topic'
          name='course-topic'
          value={courseTopic}
          onChange={handleChange}
          required
        />
        <label className='form-label'>Lesson Name</label>
        <input
          type='text'
          placeholder='Lesson Name'
          name='lesson-name'
          value={lessonName}
          onChange={handleChange}
          required
        />
        <label className='form-label'>Description</label>
        <textarea
          type='text'
          name='description'
          placeholder='Description'
          rows='7'
          value={description}
          onChange={handleChange}
          required
        />
        <label className='form-label'>Vimeo URL</label>
        <input
          type='url'
          placeholder='https://vimeo.com/#########'
          name='vimeo-url'
          value={vimeoUrl}
          onChange={handleChange}
          required
        />

        <div className='add-lesson-lesson-btn-container'>
          <button
            className='add-lesson-btn add-lesson-yes'
            type='submit'
            onClick={onFormSubmit}
          >
            Add Lesson
          </button>
          <button
            className='add-lesson-btn add-lesson-no'
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </>
    );
  }
  return <div className='add-lesson-form'>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    error: state.course.error,
    loading: state.course.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddCourseVideo: (token, courseSection) =>
      dispatch(actions.addCourseVideo(token, courseSection)),
    onLogout: () => actions.logout(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLessonForm);
