import React, { Component } from 'react';
import './footer-bar.scss';
import ULTRA_LOGO from '../../assets/ultra-main-logo.png';

class FooterBar extends Component {
  render() {
    return (
      <div className='footer-bar'>
        <div className='main-footer-bar'>
          <img
            src={ULTRA_LOGO}
            className='footer-logo'
            alt='ultra-logo-footer'
          />
        </div>
        <div className='bottom-footer-bar'></div>
      </div>
    );
  }
}

export default FooterBar;
