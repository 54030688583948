import React from 'react';
import './all-course-video-admin.scss';

const AllCourseVideoAdmin = ({ adminStatus }) => {
  let content = <h3></h3>;
  if (adminStatus) {
    content = <h3 className='admin-notice'>You are logged in as an admin.</h3>;
  }
  return <>{content}</>;
};

export default AllCourseVideoAdmin;
