import React from 'react';
import { Modal } from 'react-bootstrap'; 
import './course-overview-popup.scss';

const CourseOverviewPopup = ({courseName, courseOverview, handleClose, show}) => {
    const courseOverviewList = courseOverview.map(({name}, index) => {
        return (
            
                <p className='overview-item' key={`${courseName}${name}${index}`}>{index+1}. {name}</p>
            
            
        );
    });
    return (
        <>
            <Modal show={show} onHide={handleClose} className='course-overview-popup'>
                <Modal.Body className='modal-body' >
                    <h3 className="course-overview-name">{courseName} Overview</h3>
                    <div className='overview-item-container'>
                        {courseOverviewList}
                    </div>
                </Modal.Body>
            </Modal>
            
        </>
    )
}

export default CourseOverviewPopup;
