import * as actionTypes from '../actions/actionTypes';

const initialState = {
  allCourseVideos: null,
  currentSectionVideos: [],
  specificVideo: null,
  specificVideoId: null,
  error: null,
  loading: false,
  userWatchedVideos: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COURSE_VIDEO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_COURSE_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        currentSectionVideos: action.courseData,
      };
    }
    case actionTypes.ADD_COURSE_VIDEO_FAIL: {
      return {
        ...state,
        loading: false,
        // error: action.error
        currentSectionVideos: [],
      };
    }
    case actionTypes.DELETE_COURSE_VIDEO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_COURSE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentSectionVideos: action.courseData,
      };
    case actionTypes.DELETE_COURSE_VIDEO_FAIL:
      return {
        ...state,
        loading: false,
        // error: action.error
        currentSectionVideos: [],
      };
    case actionTypes.UPDATE_COURSE_VIDEO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_COURSE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentSectionVideos: action.updatedCourseData,
      };
    case actionTypes.UPDATE_COURSE_VIDEO_FAIL:
      return {
        ...state,
        loading: false,
        // error: action.error
        currentSectionVideos: [],
      };
    case actionTypes.UPDATE_COURSE_ORDER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_COURSE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentSectionVideos: action.updatedCourseData,
      };
    case actionTypes.UPDATE_COURSE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        // error: action.error
        currentSectionVideos: [],
      };
    case actionTypes.GET_ALL_COURSE_VIDEOS:
      return state;
    case actionTypes.GET_ALL_COURSE_VIDEOS_FROM_SECTION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_ALL_COURSE_VIDEOS_FROM_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentSectionVideos: action.courseData,
      };
    case actionTypes.GET_ALL_COURSE_VIDEOS_FROM_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        currentSectionVideos: [],
      };
    case actionTypes.GET_COURSE_VIDEO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_COURSE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        specificVideo: action.videoData,
        specificVideoId: action.videoData.id,
      };
    case actionTypes.GET_COURSE_VIDEO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        specificVideo: null,
        specificVideoId: null,
      };
    case actionTypes.GET_USER_WATCHED_VIDEOS_START:
      return {
        ...state,
      };
    case actionTypes.GET_USER_WATCHED_VIDEOS_SUCCESS:
      return {
        ...state,
        userWatchedVideos: action.userWatchedVideosData,
      };
    case actionTypes.GET_USER_WATCHED_VIDEOS_FAIL:
      return {
        ...state,
        loading: false,
        // error: action.error,
        specificVideo: null,
        specificVideoId: null,
        userWatchedVideos: null,
      };
    default:
      return state;
  }
};

export default reducer;
