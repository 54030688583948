import React from 'react'
import CourseCard from './course-card/course-card';
import './course-cards.scss';

const CourseCards = ({COURSES_DATA}) => {
    const courseCard = COURSES_DATA.map(({id, courseName, courseOverview}) => {
        return (
            <CourseCard key={id} courseName={courseName} courseOverview={courseOverview}/>
        );
    })
    return (
        <div className="course-cards">
            {courseCard}
        </div>
    );
}

export default CourseCards;
