import React from 'react';
import { Link } from 'react-router-dom';
import './error-display.scss';

const ErrorDisplay = ({ message, display }) => {
  let content = null;
  if (display === 'normal') {
    content = (
      <div className='error-container'>
        <p className='error-msg'>{message}</p>
        <Link
          className='error-return-home'
          to={{
            pathname: `/`,
          }}
        >
          <button className='error-return-home'>Return To Home Page</button>
        </Link>
      </div>
    );
  }

  return <>{content}</>;
};

export default ErrorDisplay;
