import React from 'react';
import './authentication.scss';
import ZEN_PLANNER_LOGO from '../../assets/zen-planner-logo.png';

const Authentication = () => {
  return (
    <div className='auth-container'>
      <img
        src={ZEN_PLANNER_LOGO}
        alt='zen planner logo'
        className='zen-planner-logo'
      />
      <h2 className='auth-text'>Authenticating Your Zen Planner Login...</h2>
      <div class='spinner'>
        <div class='rect1'></div>
        <div class='rect2'></div>
        <div class='rect3'></div>
        <div class='rect4'></div>
        <div class='rect5'></div>
      </div>
    </div>
  );
};

export default Authentication;
