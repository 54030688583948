const COURSES_DATA = [
  {
    id: 1,
    courseName: 'Miscellaneous',
    courseOverview: [
      {
        name: 'Tapping (Hand, Foot, Verbal)',
      },
      {
        name: 'Break Falls (Back, Side, Front)',
      },
      {
        name: 'Belt Tying',
      },
      {
        name: 'Etiquette',
      },
      // {
      //   name: 'Points System',
      // },
    ],
  },
  {
    id: 2,
    courseName: 'Solo Movements',
    courseOverview: [
      {
        name: 'Bridge',
      },
      {
        name: 'Shrimp Out',
      },
      {
        name: 'Shrimp In',
      },
      {
        name: 'Shrimp To Knees',
      },
      {
        name: 'Scissor Legs To Knees',
      },
      {
        name: 'Front Roll',
      },
      {
        name: 'Back Roll',
      },
      {
        name: 'Half Granby',
      },
      {
        name: 'Full Granby',
      },
      {
        name: 'Sit Out',
      },
      {
        name: 'Technical Get-Up',
      },
      {
        name: 'Sprawl',
      },
    ],
  },
  {
    id: 3,
    courseName: 'Terms',
    courseOverview: [
      {
        name: 'Collar',
      },
      {
        name: 'Cuff (Wrist, Ankle)',
      },
      {
        name: 'Lapel',
      },
      {
        name: 'Underhook',
      },
      {
        name: 'Overhook',
      },
      {
        name: 'Cross Grip',
      },
      {
        name: 'Same Side Grip',
      },
      {
        name: 'Pistol Grip',
      },
      {
        name: 'Cat’s Paw Or Pocket Grip',
      },
      {
        name: 'Horse Collar Grip',
      },
      {
        name: 'Lapel Grip',
      },
      {
        name: 'Belt Grip (Free End, Knot, Body)',
      },
      {
        name: 'Illegal Grip',
      },
      {
        name: 'Seat Belt',
      },
      {
        name: 'Hooks',
      },
      {
        name: 'Backpack',
      },
      {
        name: 'Cross Face',
      },
      {
        name: 'Chin Strap',
      },
      {
        name: 'Whizzer',
      },
      {
        name: 'Stack',
      },
      {
        name: 'Sprawl',
      },
      {
        name: 'Combat Knee',
      },
      {
        name: 'Mount',
      },
      {
        name: 'Side Control',
      },
      {
        name: 'Closed Guard',
      },
      {
        name: 'Body Triangle',
      },
      {
        name: '1/2 Guard',
      },
      {
        name: 'N-S',
      },
      {
        name: 'Knee Belly',
      },
      {
        name: 'Open Guard',
      },
      {
        name: '50/50',
      },
      {
        name: 'Turtle',
      },
      {
        name: 'Ready/Fighting Stance',
      },
    ],
  },
  {
    id: 4,
    courseName: 'Drills',
    courseOverview: [
      {
        name: 'Toreando',
      },
      {
        name: 'Toreando, Knee Belly To Other Side',
      },
      {
        name: 'Windshield Over Legs',
      },
      {
        name: 'Around The Head',
      },
      {
        name: 'Leg Drag',
      },
      {
        name: 'X-Pass',
      },
      {
        name: 'Fake X-Pass To Leg Drag',
      },
      {
        name: 'Granby Roll, Feet On Wall',
      },
      {
        name: 'Granby Roll W/ Partner',
      },
      {
        name: 'Long Step',
      },
      {
        name: 'Drop Level, Shoot',
      },
      {
        name: 'Duck Walk (Knee-Toe)',
      },
    ],
  },
  {
    id: 5,
    courseName: 'Techniques',
    courseOverview: [
      {
        name: 'Stand Up',
      },
      {
        name: 'Open Guard',
      },
      {
        name: 'Closed Guard',
      },
      {
        name: 'Half Guard',
      },
      {
        name: 'Mount',
      },
      {
        name: 'Knee on Belly',
      },
      {
        name: 'North-South',
      },
      {
        name: 'Back',
      },
      {
        name: 'Turtle',
      },
      {
        name: '50/50',
      },
    ],
  },
];

export default COURSES_DATA;
