import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './course-card.scss';

import CourseOverviewPopup from '../../popups/course-overview-popup/course-overview-popup';

const CourseCard = ({ courseName, courseOverview }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let courseNameLink = courseName.split(' ').join('-');
  return (
    <>
      <div className='course-card'>
        <h3>{courseName}</h3>
        <p className='course-overview' onClick={handleShow}>
          Course Overview
        </p>
        <Link
          className='view-course'
          to={{
            pathname: `/course/${courseNameLink.toLowerCase()}`,
            state: {
              courseName,
            },
          }}
        >
          <p>view course</p>
        </Link>
      </div>
      <CourseOverviewPopup
        courseName={courseName}
        courseOverview={courseOverview}
        handleClose={handleClose}
        show={show}
      />
    </>
  );
};

export default CourseCard;
