// Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// Add video to course
export const ADD_COURSE_VIDEO = 'ADD_COURSE_VIDEO';
export const ADD_COURSE_VIDEO_START = 'ADD_COURSE_VIDEO_START';
export const ADD_COURSE_VIDEO_SUCCESS = 'ADD_COURSE_VIDEO_SUCCESS';
export const ADD_COURSE_VIDEO_FAIL = 'ADD_COURSE_VIDEO_FAIL';

// Delete Course Video
export const DELETE_COURSE_VIDEO = 'DELETE_COURSE_VIDEO';
export const DELETE_COURSE_VIDEO_START = 'DELETE_COURSE_VIDEO_START';
export const DELETE_COURSE_VIDEO_SUCCESS = 'DELETE_COURSE_VIDEO_SUCCESS';
export const DELETE_COURSE_VIDEO_FAIL = 'DELETE_COURSE_VIDEO_FAIL';

// Update Course Video
export const UPDATE_COURSE_VIDEO = 'UPDATE_COURSE_VIDEO';
export const UPDATE_COURSE_VIDEO_START = 'UPDATE_COURSE_VIDEO_START';
export const UPDATE_COURSE_VIDEO_SUCCESS = 'UPDATE_COURSE_VIDEO_SUCCESS';
export const UPDATE_COURSE_VIDEO_FAIL = 'UPDATE_COURSE_VIDEO_FAIL';

// Update Course Order
export const UPDATE_COURSE_ORDER = 'UPDATE_COURSE_ORDER';
export const UPDATE_COURSE_ORDER_START = 'UPDATE_COURSE_ORDER_START';
export const UPDATE_COURSE_ORDER_SUCCESS = 'UPDATE_COURSE_ORDER_SUCCESS';
export const UPDATE_COURSE_ORDER_FAIL = 'UPDATE_COURSE_ORDER_FAIL';

// Get all course videos
export const GET_ALL_COURSE_VIDEOS = 'GET_ALL_COURSE_VIDEOS';

// Get specific course video
export const GET_COURSE_VIDEO = 'GET_COURSE_VIDEO';
export const GET_COURSE_VIDEO_START = 'GET_COURSE_START';
export const GET_COURSE_VIDEO_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_VIDEO_FAIL = 'GET_COURSE_FAIL';

// Get all course videos from a specific section
export const GET_ALL_COURSE_VIDEOS_FROM_SECTION =
  'GET_ALL_COURSE_VIDEOS_FROM_SECTION';
export const GET_ALL_COURSE_VIDEOS_FROM_SECTION_START =
  'GET_ALL_COURSE_VIDEOS_FROM_SECTION_START';
export const GET_ALL_COURSE_VIDEOS_FROM_SECTION_SUCCESS =
  'GET_ALL_COURSE_VIDEOS_FROM_SECTION_SUCCESS';
export const GET_ALL_COURSE_VIDEOS_FROM_SECTION_FAIL =
  'GET_ALL_COURSE_VIDEOS_FROM_SECTION_FAIL';

// Store User Watched Video
export const STORE_USER_WATCHED_VIDEO = 'STORE_USER_WATCHED_VIDEO';
export const STORE_USER_WATCHED_VIDEO_SUCCESS =
  'STORE_USER_WATCHED_VIDEO_SUCCESS';
export const STORE_USER_WATCHED_VIDEO_FAIL =
  'STORE_USER_WATCHED_VIDEO_SUCCESS_FAIL';

// Get User Watched Video
export const GET_USER_WATCHED_VIDEOS = 'GET_USER_WATCHED_VIDEO';
export const GET_USER_WATCHED_VIDEOS_START = 'GET_USER_WATCHED_VIDEO_START';
export const GET_USER_WATCHED_VIDEOS_SUCCESS = 'GET_USER_WATCHED_VIDEO_SUCCESS';
export const GET_USER_WATCHED_VIDEOS_FAIL =
  'GET_USER_WATCHED_VIDEO_SUCCESS_FAIL';
