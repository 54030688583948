import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import './navigation-bar.scss';
import ULTRA_LOGO from '../../assets/ultra-main-logo.png';

const NavigationBar = ({ onLogout, username }) => {
  let usernameText = null;
  if (username) {
    usernameText = <p className='username-text'>{username}</p>;
  }
  return (
    <nav className='navigation-bar'>
      <nav className='top-nav-bar'>
        <div>
          <span>Santa Clarita</span>{' '}
          <a href='tel: 661-388-4200'>661-388-4200</a>
        </div>
        <div>
          <span>Still have questions?</span>{' '}
          <a href='mailto: info@ultrajiujitsu.com'>info@ultrajiujitsu.com</a>
        </div>
      </nav>
      <nav className='main-nav-bar'>
        <Link to='/'>
          <img
            src={ULTRA_LOGO}
            className='nav-logo'
            alt='ultra jiu jitsu logo'
          />
        </Link>
        <div className='main-nav-bar-right'>
          {usernameText}
          <p className='log-out-btn' onClick={() => onLogout()}>
            Log Out
          </p>
        </div>
      </nav>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => actions.logout(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
