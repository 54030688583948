import React, { useState } from 'react';
import './image-lightbox.scss';
const ImageLightBox = ({ imageLinks }) => {
  let imageContent = imageLinks.map((image, index) => {
    return (
      <span className='image-container' key={`${image.src}${index}`}>
        <img
          className='image'
          src={require(`../../assets/gallery/terms/${image.src}`)}
          alt={image.name}
        />
        <br />
        <p className='image-text'>{image.name}</p>
      </span>
    );
  });

  return <div className='image-container'>{imageContent}</div>;
};

export default ImageLightBox;
