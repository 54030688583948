import React from 'react';
import './loading-indicator.scss';

const LoadingIndicator = ({ loadingText }) => {
  return (
    <div className='loading-indicator-container'>
      <p className='loading-text'>{loadingText}</p>
      <div className='lds-roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
