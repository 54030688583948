import axios from '../../axios-config';
import * as actionTypes from './actionTypes';

// Add Course Video
export const addCourseVideo = (token, newLessonData) => {
  return (dispatch) => {
    dispatch(addCourseVideoStart());
    axios
      .post(`coursevideos/addcoursevideo`, newLessonData, {
        headers: {
          'x-auth-token': token,
        },
      })
      .then((res) => {
        dispatch(addCourseVideoSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(addCourseVideoFail(err));
      });
  };
};

export const addCourseVideoStart = () => {
  return {
    type: actionTypes.ADD_COURSE_VIDEO_START,
  };
};

export const addCourseVideoSuccess = (courseData) => {
  return {
    type: actionTypes.ADD_COURSE_VIDEO_SUCCESS,
    courseData: courseData,
  };
};

export const addCourseVideoFail = (error) => {
  return {
    type: actionTypes.ADD_COURSE_VIDEO_FAIL,
    error: error,
  };
};

// Delete Course Video
export const deleteCourseVideo = (token, id, courseSection) => {
  return (dispatch) => {
    dispatch(deleteCourseVideoStart());
    axios
      .post(
        `coursevideos/video/${id}`,
        { course_section: courseSection },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      )
      .then((res) => {
        dispatch(deleteCourseVideoSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(deleteCourseVideoFail(err));
      });
  };
};

export const deleteCourseVideoStart = () => {
  return {
    type: actionTypes.DELETE_COURSE_VIDEO_START,
  };
};

export const deleteCourseVideoSuccess = (courseData) => {
  return {
    type: actionTypes.DELETE_COURSE_VIDEO_SUCCESS,
    courseData: courseData,
  };
};

export const deleteCourseVideoFail = (error) => {
  return {
    type: actionTypes.DELETE_COURSE_VIDEO_FAIL,
  };
};

// Update Course Video
export const updateCourseVideo = (token, id, updatedLessonData) => {
  return (dispatch) => {
    dispatch(updateCourseVideoStart());
    axios
      .post(`coursevideos/updatecoursevideo/${id}`, updatedLessonData, {
        headers: {
          'x-auth-token': token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        dispatch(updateCourseVideoSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateCourseVideoFail(err));
      });
  };
};

export const updateCourseVideoStart = () => {
  return {
    type: actionTypes.UPDATE_COURSE_VIDEO_START,
  };
};
export const updateCourseVideoSuccess = (updatedCourseData) => {
  return {
    type: actionTypes.UPDATE_COURSE_VIDEO_SUCCESS,
    updatedCourseData: updatedCourseData,
  };
};

export const updateCourseVideoFail = (error) => {
  return {
    type: actionTypes.UPDATE_COURSE_VIDEO_FAIL,
    error: error,
  };
};

// Update Course Order
export const updateCourseOrder = (token, requestData) => {
  return (dispatch) => {
    // dispatch(updateCourseOrderStart());
    axios
      .post(`coursevideos/updateorder`, requestData, {
        headers: {
          'x-auth-token': token,
        },
      })
      .then((res) => {
        dispatch(updateCourseOrderSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateCourseOrderFail(err));
      });
  };
};

export const updateCourseOrderStart = () => {
  return {
    type: actionTypes.UPDATE_COURSE_ORDER_START,
  };
};
export const updateCourseOrderSuccess = (updatedCourseData) => {
  return {
    type: actionTypes.UPDATE_COURSE_ORDER_SUCCESS,
    updatedCourseData: updatedCourseData,
  };
};

export const updateCourseOrderFail = (error) => {
  return {
    type: actionTypes.UPDATE_COURSE_ORDER_FAIL,
    error: error,
  };
};

// Get All Course Videos From Section
export const getAllCourseVideosFromSection = (token, courseSection) => {
  return (dispatch) => {
    dispatch(getAllCourseVideosFromSectionStart());
    axios
      .get(`coursevideos/section/${courseSection}`, {
        headers: {
          'x-auth-token': token,
        },
      })
      .then((res) => {
        dispatch(getAllCourseVideosFromSectionSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getAllCourseVideosFromSectionFail(err.response.data));
      });
  };
};

export const getAllCourseVideosFromSectionStart = () => {
  return {
    type: actionTypes.GET_ALL_COURSE_VIDEOS_FROM_SECTION_START,
  };
};

export const getAllCourseVideosFromSectionSuccess = (courseData) => {
  return {
    type: actionTypes.GET_ALL_COURSE_VIDEOS_FROM_SECTION_SUCCESS,
    courseData: courseData,
  };
};

export const getAllCourseVideosFromSectionFail = (error) => {
  return {
    type: actionTypes.GET_ALL_COURSE_VIDEOS_FROM_SECTION_FAIL,
    error: error,
  };
};

// Get Specific Course Video
export const getCourseVideo = (token, id) => {
  return (dispatch) => {
    dispatch(getAllCourseVideosFromSectionStart());
    axios
      .get(`coursevideos/video/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      })
      .then((res) => {
        // console.log(res.data[0]);
        dispatch(getCourseVideoSuccess(res.data[0]));
      })
      .catch((err) => {
        dispatch(getCourseVideoFail(err.response.data));
      });
  };
};
export const getCourseVideoStart = () => {
  return {
    type: actionTypes.GET_COURSE_VIDEO_START,
  };
};
export const getCourseVideoSuccess = (videoData) => {
  return {
    type: actionTypes.GET_COURSE_VIDEO_SUCCESS,
    videoData: videoData,
  };
};
export const getCourseVideoFail = (error) => {
  return {
    type: actionTypes.GET_COURSE_VIDEO_FAIL,
    error: error,
  };
};

// Store User Watched Video
export const storeUserWatchedVideo = (token, userID, videoID) => {
  return (dispatch) => {
    axios
      .post(
        `coursevideos/watched`,
        { userID, videoID },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      )
      .then((res) => {
        dispatch(storeUserWatchedVideoSuccess());
      })
      .catch((err) => {
        console.log(err);
        dispatch(storeUserWatchedVideoFail(err));
      });
  };
};

export const storeUserWatchedVideoSuccess = () => {
  return {
    type: actionTypes.STORE_USER_WATCHED_VIDEO_SUCCESS,
  };
};

export const storeUserWatchedVideoFail = (error) => {
  return {
    type: actionTypes.STORE_USER_WATCHED_VIDEO_FAIL,
    error: error,
  };
};

// Get User Watched Videos
export const getUserWatchedVideos = (token, userID) => {
  return (dispatch) => {
    dispatch(getUserWatchedVideosStart());
    axios
      .get(`coursevideos/watched/${userID}`, {
        headers: {
          'x-auth-token': token,
        },
      })
      .then((res) => {
        dispatch(getUserWatchedVideosSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getUserWatchedVideosFail(err));
      });
  };
};

export const getUserWatchedVideosStart = () => {
  return {
    type: actionTypes.GET_USER_WATCHED_VIDEOS_START,
  };
};
export const getUserWatchedVideosSuccess = (userWatchedVideosData) => {
  return {
    type: actionTypes.GET_USER_WATCHED_VIDEOS_SUCCESS,
    userWatchedVideosData: userWatchedVideosData,
  };
};
export const getUserWatchedVideosFail = (error) => {
  return {
    type: actionTypes.GET_USER_WATCHED_VIDEOS_FAIL,
    error: error,
  };
};
